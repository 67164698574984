.page-products {
	.module-breadcrumb {
		margin-top: 0;
		margin-bottom: 0;

		@include breakpoint(sm){
			margin-top: 100px;
		}

	}
}

.catalog-product-view,
.products.products-grid {
	.product-items {
		@include make-row();

		margin-left: -15px !important;



		.item.product.product-item {
			@include breakpoint(xxs-only){
				width: 100%;
			}
			@include make-sm-column(6);
			@include make-md-column(4);

			margin-bottom: 30px;
			margin-left: 0;



			.product-item-info {
				width: 100%;
				border-bottom: 2px solid $MAIN_BORDER_COLOR;
				padding-bottom: 15px;
			}



			.product-item-photo {
				display: block;
				position: relative;
			}



			.product-image-container {
				width: 100% !important;
				padding: 1px;
				border: 1px solid $MAIN_BORDER_COLOR;
			}



			.product-item-name {
				margin: 20px 0;
				a {
					color: $SECONDARY_LIGHT_COLOR;
					@include font-DIN-next-regular(remcalc(20px));
				}
			}

			.description-short-category {
				margin-right: 0.5em;
				font-family: 'DIN Next W02 Regular', sans-serif;
				font-size: .88889rem;
				line-height: 1.44444rem;
				font-weight: normal;
				margin-bottom: 20px;
			}

			ul {
				padding: 0;
				list-style: none;
			}



			.product-item-details {
				position: relative;

				padding-bottom: 16px + 11px;

				@include breakpoint(sm) {
					padding-bottom: 21px + 14px;
				}



				.product-item-inner {
					.product-attributes {
						dd {
							margin-bottom: 4px;
						}
					}



					.price-final_price-wrapper {
						position: absolute;
						right: 0;
						bottom: 11px;
						left: 0;

						height: 16px;

                        .price-container {
                            span.price-label {
                                position: absolute;
                                width: 0;
                                height: 0;
                                overflow: hidden;
                            }
                        }

						.price-box.price-final_price {
							margin: 0;



							dd,
							.price-container {
								margin-bottom: 0;
								.price {
									@include font-DIN-next-bold(remcalc(18px));
									color: $SECONDARY_LIGHT_COLOR;
								}
							}
						}



						@include breakpoint(sm) {
							bottom: 14px;

							height: 21px;
						}
					}



					.price-box.additional-pricer {
						position: absolute;
						right: 0;
						bottom: 0;
						left: 0;

						height: 11px;

						margin: 0;



						dt,
						dd {
							@include font-DIN-next-regular(remcalc(12px));
						}



						@include breakpoint(sm) {
							height: 14px;
						}
					}
				}
			}
		}



		.product-item-details {
			min-height: 100px;
		}
	}
}

.toolbar-products {
	padding: 0 0 21px;
	border-bottom: 1px solid $MAIN_BORDER_COLOR;

	.toolbar-amount {
		.toolbar-number {
			@include font-DIN-next-bold(remcalc(18px));
			color: $SECONDARY_LIGHT_COLOR;
		}
	}

	.pages {
		.btn {
			width: 38px;
			height: 38px;
			line-height: 38px !important;
			padding: 2px 0 0 0 !important;
			border: none;
		}
	}

	.toolbar-sorter {
		.sorter-label {
			float: left;
			@include font-DIN-next-light(remcalc(18px), 38px);
			margin-right: 10px;
		}
		.selectproxy {
			width: auto;
			min-width: 174px;
			float: left;
			text-align: left;
			height: 38px !important;

			.selectlabel {
				border: 1px solid $MAIN_LIGHT_COLOR;
				border-right: none !important;
				&::after {
					top: -2px;
				}
			}
		}

		.sorter-action {
			cursor: pointer;

			width: 38px;
			height: 38px;

			position: relative;

			margin-left: 10px;

			color: $link-color;

			@include touch-hover-fix(){
				color: $link-hover-color;
			}

			&.sort-desc,
			&.sort-asc {

				&:after,
				&:before {
					position: absolute;
					left: 0;
					right: 0;
					@extend .dolle-icon;
					font-size: 30px;
				}

				&:before {
					top: 5px;
					//color: rgb(210, 210, 210);
				}
				&:after {
					bottom: 5px;
					//color: rgb(209, 0, 25);
				}

			}
			&.sort-asc {
				&:before {
					content: $icon-sorting_asc-path1;
				}
				&:after {
					content: $icon-sorting_asc-path2;
				}
			}
			&.sort-desc {
				&:before {
					content: $icon-sorting_desc-path1;
				}
				&:after {
					content: $icon-sorting_desc-path2;
				}
			}
		}
	}
	.field.limiter {
		> .label {
			display: none;
		}
		.selectproxy {
			min-width: 90px;
			text-align: left;
			line-height: 30px;
			.selectlabel {
				padding-left: 15px;
				line-height: 40px;
			}
		}
    }



	.pages {
		float: right !important;

		@include breakpoint(xs-only) {
			float: none !important;
		}

		.action > span {
			border: 0;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		.item {
			margin: 1px 20px 0 0;
			font-size: remcalc(18px);

			&.pages-item-next {
				margin-left: 10px;
			}

			&.pages-item-previous {
				margin-right: 30px;
			}
		}

		a.page {
			font-weight: normal;
			color: $MAIN_COLOR;
		}

		strong.page {
			@include font-DIN-next-bold(remcalc(18px), 32px);
			color: $SECONDARY_LIGHT_COLOR;
		}
	}
}
.products.wrapper~.toolbar {
    &.toolbar-products {
        .limiter {
            float: left;
        }
    }

}
.block.filter {
	.filter-title {
		border-bottom: 1px solid $MAIN_BORDER_COLOR;
		margin-bottom: remcalc(20px);

		@include font-DIN-next-bold(remcalc(20px), remcalc(56px));
		text-transform: uppercase;
	}

	.block-actions + .filter-title {
		margin-top: remcalc(40px);
	}



	.filter-current {
		.item {
			position: relative;

			margin-bottom: remcalc(20px);

			&:first-of-type {
				margin-top: remcalc(15px);
			}



			.filter-label {
				display: block;

				@include font-DIN-next-regular(remcalc(20px));
				text-transform: uppercase;
			}



			.filter-value {
				display: block;

				@include font-DIN-next-light(remcalc(20px));
			}



			.action.remove {
				display: block;
				cursor: pointer;
				overflow: hidden;
				user-select: none;

				position: absolute;
				top: 0;
				right: 0;

				width: 16px;
				height: 55px;

				color: transparent;

				&:after {
					@extend [class^="icon-"];

					content: $icon-x_icon;

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					color: $SECONDARY_LIGHT_COLOR;
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
	}

	.filter-current + .block-actions {
		cursor: pointer;

		padding: 0 0 21px;
		border-bottom: 1px solid $MAIN_BORDER_COLOR;
		outline: 0;

		color: $link-color;

		@include touch-hover-fix(){
			color: $link-hover-color;
		}
	}



	.filter-content {
		padding: 0;
		float: none;
	}

	.filter-options-title {
		display: block;
		width: 100%;
	}
	.filter-options-content {
		text-align: left;
		.items{
			.item{
				padding-top: 4px;
			}
		}
		.magic-filter {

			&:hover {
				color: $SECONDARY_LIGHT_COLOR;
				cursor: pointer;
			}
		}
	}

	.panel-title {
		a {
			@include font-DIN-next-regular(remcalc(20px));
			color: $MAIN_COLOR;
		}
	}

	.panel-body {
		.item {
			a {
				@include font-DIN-next-light(remcalc(20px));
				color: $MAIN_COLOR;
				.count {
					&:before {
						content: "(";
					}
					&:after {
						content: ")";
					}
				}
			}
		}
	}
}

.page-layout-1column {
	.catalog-product-view,
	.products.products-grid {
		.product-items {
			.item.product.product-item {
				@include breakpoint(xxs-only){
					width: 100%;
				}
				@include make-xs-column(6);
				@include make-sm-column(3);
			}
		}
	}
}
