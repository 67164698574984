.authentication-wrapper {
  display: none;
}

.modal-popup.confirm {
  .action-close {
    padding: 2rem 3rem;
  }

  @include breakpoint(md) {
    .modal-inner-wrap {
      width: 40%;
      max-width: 600px;
    }
  }
}

[class^="checkout-"] {
  .product-options, .item-options {
    dd {
      font-family: 'DIN Next W02 Regular', sans-serif;
    }
  }
}

.checkout-header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-top: -26px;

  h4 {
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .trusted-security-icons h4 {
    text-align: center;
  }


  .order-questions h4 {
    text-align: right;
  }

  .order-questions__phone {
    font-family: 'DIN Next W02 Light', sans-serif;
    font-size: 1.66667rem;
    line-height: 1.2;
    font-weight: 600;
    color: $gray-base;
    text-transform: uppercase;

    a, a:hover {
      color: $gray-base;
      text-decoration: none;
    }

    &:before {
      content: '';
      display: inline-block;
      background-image: url("../img/dolle_icon_phone_checkoutheader.svg");
      background-position: center center;
      background-repeat: no-repeat;
      padding-left: 40px;
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: $screen-md) {
    .payment-method-icons {
      display: none;
    }
    .trusted-security-icons {
      margin-left: 30%;
      @media (max-width: $screen-xs) {
        margin-left: 8%;
      }
    }

    .order-questions {
      margin-right: 10%;
      @media (max-width: $screen-xs) {
        margin-right: 2%;
        .order-questions__phone a {
          font-size: 0.9em;
        }
      }
    }
  }
}

.checkout-index-index {
  header.main-header-container .site-header .site-header-row .mainnav-header {
    padding-right: 0;
  }

  @media (max-width: $screen-md) {
    .columns #checkout.checkout-container {
      margin-top: 70px
    }
    header.main-header-container .site-header .site-header-row .mainnav-header {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .field-error {
    font-size: 1rem;
  }
}

.checkout-methods-items, .quickcart-bottom-container, .opc-wrapper {
  .item {
    width: 100%;

    &-payment {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .extra-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .paypal {
      img {
        display: inline-block;
        margin: 0;
      }
    }
  }

  .paypal.checkout.after, .amazon-button-container {
    &:not(.amazon-payment-button-container) {
      background-color: $BOX_BG_COLOR_LIGHT;
      width: calc((100% / 2) - 15px);
      height: 40px;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    img {
      max-width: none;
    }

    @media (max-width: $screen-xs) {
      width: 100%;
    }
  }

  .paypal.checkout.after {
    position: relative;

    &:before {
      content: none;
    }

    &:after {
      background-color: $PAYPAL_DARK_BLUE;
    }

    > input {
      opacity: 0;
      position: absolute;
      z-index: 99;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }


    .paypal-logo-wrapper {
      @include font-DIN-next-bold(remcalc(18px));

      strong {
        margin-right: 5px;
      }
    }

    @media (max-width: $screen-xs) {
      margin-bottom: 15px;
    }
  }

  .item-method-info {
    em {
      display: block;
      margin: 10px auto;
      color: $PAYMENT_LABEL_COLOR;
    }

    &:last-of-type {
      @include font-DIN-next-light(remcalc(18px));
      margin-top: 20px;

      strong {
        @include font-DIN-next-bold(remcalc(18px));
      }
    }
  }
}

#checkout.checkout-container {
  @include make-xs-column(12);

  margin-top: 20px;

  @include breakpoint(sm) {
    margin-top: 70px;
  }


  .opc-progress-bar {
    @extend .hidden-xs;


    .opc-progress-bar-item {
      @include make-xs-column(6);

      position: relative;

      height: 40px;

      line-height: 40px;
      color: $MAIN_COLOR;
      vertical-align: bottom;

      background-color: $MAIN_LIGHT_COLOR;

      &._active {
        color: #fff;

        background-color: $SECONDARY_LIGHT_COLOR;


        span {
          color: #fff;
        }
      }

      &:before {
        display: none;
      }

      &:first-child {
        width: calc(50% - 10px);

        &:after {
          content: '';

          position: absolute;
          right: -21px;
          z-index: 1;

          width: 0;
          height: 0;

          border-style: solid;
          border-width: 20px 0 20px 21px;
          border-color: transparent transparent transparent $SECONDARY_LIGHT_COLOR;

          transform: rotate(360deg);
        }
      }

      &._complete {
        &:after {
          border-color: transparent transparent transparent $MAIN_LIGHT_COLOR;
        }
      }


      span {
        padding-top: 0;

        @include font-DIN-next-regular(remcalc(20px), remcalc(40px));
        color: $MAIN_COLOR;
        text-transform: uppercase;

        &:after,
        &:before {
          display: none;
        }
      }
    }
  }


  // mobile layout
  @media (max-width: $screen-sm-min) {
    .opc-estimated-wrapper {
      position: relative;

      padding: 15px 0 0 0;
      border-top: 1px solid $MAIN_BORDER_COLOR;
      border-bottom: 0;


      .estimated-block {
        padding-right: 60px;

        @include font-DIN-next-regular(remcalc(30px));
        color: $SECONDARY_LIGHT_COLOR;
        font-weight: normal;


        .estimated-label {
          margin-bottom: 0;
        }
      }

      .minicart-wrapper {
        .counter.qty {
          top: 0px;
        }
      }
    }

    .modal-custom.opc-sidebar.opc-summary-wrapper {
      z-index: 1000;
    }


    #shipping {
      > * {
        float: none;
      }


      > .step-title {
        margin-top: 36px;
      }


      > .step-content {
        > form {
          margin-top: 16px;
        }
      }
    }


    .custom-slide .modal-inner-wrap {
      margin-top: 51px;

      background-color: $BG_COLOR;
    }
  }


  .opc-sidebar {
    @include breakpoint(sm) {
      width: 100%;
    }

    &.opc-summary-wrapper {
      .modal-content {
        position: static;

        border: 0;
        border-radius: 0;
        outline: 0;

        background-color: #fff;

        box-shadow: none;

        @media (max-width: $screen-sm-min) {
          width: 90%;

          margin: 0 auto;
        }
      }


      .modal-header {
        padding: 15px;
        border-bottom: 0;
      }


      .opc-block-summary {
        padding: 0 10px 0 0;
        background-color: transparent;


        .label,
        label {
          float: left;

          padding: 0 !important;

          @include font-DIN-next-light(remcalc(18px));
          color: $MAIN_COLOR !important;
        }


        .product-item {
          border-bottom: 1px solid $MAIN_BORDER_COLOR;
          font-size: inherit;

          .price {
            font-size: 1rem;
          }

          .item-options {
            .values {
              width: 60%;
              float: right;
            }
          }

          .product {
            .product-item-details {
              padding-left: 120px;


              .product.options.active {
                .content {
                  padding-top: remcalc(10px);
                }
              }
            }
          }
        }


        .totals.shipping {
          .label {
            margin-right: 8px;
          }
        }

      }


      .opc-wrapper .step-title,
      .opc-block-shipping-information .shipping-information-title,
      .opc-block-summary > .title,
      .checkout-agreements-items .checkout-agreements-item-title {
        @include font-DIN-next-regular(remcalc(30px));
        color: $MAIN_COLOR;
        text-transform: uppercase;
      }

      .opc-block-shipping-information {
        .shipping-information-title {
          @include font-DIN-next-regular(remcalc(20px));
        }

        .shipping-information-content {
          @include font-DIN-next-light(remcalc(18px));
          color: $MAIN_COLOR;
          display: inline-grid;

          a {
            margin-top: 15px;
          }

        }
      }


      mark,
      .mark {
        background-color: transparent;
      }


      .cart-totals .totals-tax-summary .mark,
      .cart-totals .totals-tax-summary .amount,
      .opc-block-summary .table-totals .totals-tax-summary .mark,
      .opc-block-summary .table-totals .totals-tax-summary .amount {
        border: 0;
      }


      .totals.sub {

        .amount,
        th {
          @include font-DIN-next-regular(remcalc(20px));
          color: $MAIN_COLOR;
          text-transform: uppercase;
        }
      }


      .grand.totals {

        strong,
        .price {
          @include font-DIN-next-regular(remcalc(30px));
          color: $SECONDARY_LIGHT_COLOR;
          text-transform: uppercase;
        }

        &.sub {
          color: $MAIN_COLOR;
        }
      }
    }


    th {
      padding-left: 0;
    }


    .totals.shipping {

      .value,
      .label {
        @include font-DIN-next-light(remcalc(18px));
        color: $MAIN_COLOR;
      }
    }


    .opc-block-shipping-information {
      .shipping-information {
        @include make-row();


        .ship-to,
        .ship-via {
          @include make-sm-column(6);


          a.action.action-edit {
            display: block;
            cursor: pointer;

            margin-top: 15px;
          }
        }
      }


      .shipping-information-title {
        border-bottom: 0;
      }
    }
  }


  .opc-block-summary > .title {
    border-bottom: 0;

    text-transform: uppercase;
  }


  .opc-block-summary .items-in-cart > .title,
  .opc-wrapper .step-title,
  .opc-block-shipping-information .shipping-information-title,
  .opc-block-summary > .title,
  .checkout-agreements-items .checkout-agreements-item-title {
    border-color: $MAIN_BORDER_COLOR !important;
  }


  .opc-block-summary .items-in-cart > .title {
    border-top: 1px solid $MAIN_LIGHT_COLOR;
    border-bottom: 0;


    strong {
      @include font-DIN-next-regular(remcalc(20px));
      text-transform: uppercase;
    }
  }


  .opc-wrapper {
    width: 100%;

    .form-login,
    .form-shipping-address {
      max-width: 100%;
    }

    .shipping-address-item {
      border: 0 none;
      width: auto;
      @include font-DIN-next-light(remcalc(18px));
      color: $MAIN_COLOR;
      padding: 0;

      .edit-address-link {
        color: $SECONDARY_LIGHT_COLOR;
        @include font-DIN-next-light(remcalc(18px));
      }

      &.selected-item {
        &:after {
          content: none;
        }
      }
    }

    .form-shipping-address {
      &:after {
        content: attr(data-hasrequired) !important;

        @include make-xs-column(12);

        margin-bottom: 40px;
      }
    }


    .checkout-shipping-address {
      .step-title {
        @include make-xs-column(12);

        border-bottom: 0;

        &.customer-shipping-address {
          border-top: 1px solid $MAIN_LIGHT_COLOR;
          margin-left: 15px;
          margin-right: 15px;
          padding: 40px 0 0;
        }
      }


      .form {
        @include clearfix();

        clear: both;

        #customer-email-fieldset {
          .field {
            margin-bottom: 0;
          }
        }
      }
    }

    #checkoutSteps {
      @include make-row();
    }

    div.field {
      @include make-xs-column(12);
      @include make-sm-column(6);

      &[name="shippingAddress.lastname"],
      &[name="shippingAddress.firstname"] {
        @include make-xs-column(6);
      }

      &#payone_creditcard_cc_type_exp_div {
        .fields.group {
          .field {
            width: 50% !important;

            padding-left: 0;


            iframe {
              // forcing all external creditcard input iframes to same height to account for
              // dropdowns being higher on iOS
              height: 30px !important;
            }
          }
        }
      }


      .control,
      .label {
        float: none;

        width: 100%;

        text-align: left;
      }


      .label:after {
        position: absolute;


        margin-left: 3px;
      }


      @include breakpoint(sm) {

        .label:after {
          top: 9px;

          margin-left: 5px;
        }
      }
    }


    fieldset.street {
      float: left;
      width: 100%;
      margin-bottom: 0;

      .control {
        width: 100%;
      }

      legend.label {
        display: none;
      }

      [name*="street.0"] {
        > .label {
          &:after {
            content: '*';
            color: #e02b27;
            font-size: 1.2rem;
            margin: 0 0 0 5px;
          }
        }
      }

      [name*="street.0"], [name*="street.1"] {
        > .label {
          display: inline-block;
          padding: 6px 15px 0 0;
          box-sizing: border-box;
          clip: auto;
          height: auto;
          margin: 0 0 5px 0;
          overflow: visible;
          position: static;
          width: auto;
        }
      }

      > .label,
      div.field {
        @include make-sm-column(12);
        text-align: left;
      }
    }

    .field.company {
      width: 100%;

      .control {
        width: 100%;
      }
    }

    .field.city, .field.country, .field.postcode {
      @include breakpoint(md) {
        width: calc(100% / 3);
      }
    }

    fieldset.payment.items {
      @include breakpoint(sm) {
        padding-left: 60px;
      }
    }


    .payment-methods {
      .payment-method-title,
      .billing-address-same-as-shipping-block {
        @include make-xs-column(12);
      }

      input[type="radio"] {
        height: auto;
      }
    }


    #opc-shipping_method {
      @include make-xs-column(12);

      .checkout-shipping-method {
        padding-top: 40px;
        border-top: 1px solid $MAIN_BORDER_COLOR;
      }


      .step-title {
        border: 0;
      }
    }


    .table-checkout-shipping-method {
      thead {
        display: none;
      }


      .col-method {
        display: none;
      }


      .col-carrier {
        padding-left: 0;

        @include font-DIN-next-bold(remcalc(18px));
      }
    }


    #checkout-shipping-method-load {
      padding-bottom: 20px;
    }


    #shipping-method-buttons-container {
      border-top: 1px solid $MAIN_BORDER_COLOR;
      margin: 0;

      > div {
        @include make-row();

        padding-top: 40px;


        > .primary {

          @include make-sm-column(6);

          .btn {
            @include font-DIN-next-regular(remcalc(18px));
            width: 100%;
            border-radius: 0;

            .icon-arrow_right {
              font-size: remcalc(14px);
            }
          }
        }
      }
    }

    .additonal-extra-fee {
      margin-bottom: 15px;
      margin-top: -20px;

      .col {
        display: inline-block;

        &:first-child {
          margin-right: 10px;
          min-width: 205px;
          @include breakpoint(md) {
            min-width: 295px;
          }
        }
      }
    }
  }


  .ssl-note {
    @include make-sm-column(4);


    p {
      min-height: 20px;

      padding: 4px 0 0 25px;

      @include font-DIN-next-regular(8px, 10px);

      background: url("../img/icon_ssl.png") no-repeat left top;
      background-size: 20px 20px;

      @include breakpoint(sm) {
        min-height: 40px;

        padding-top: 0;
        padding-left: 60px;

        font-size: 16px;
        line-height: 20px;

        background-size: 40px 40px;
      }
    }
  }


  .opc-block-shipping-information {
    padding: 0;
  }


  .checkout-payment-method {
    @include make-xs-column(12);

    .checkout-payment-method__info-label {
      border-top: 1px solid $MAIN_BORDER_COLOR;
      display: block;
      padding-top: remcalc(40px);
    }

    .step-title {
      padding-top: remcalc(40px);
      border-bottom: 0;
    }

    .payment-group {
      .step-title {
        display: none;
      }
    }


    .ccard .number .input-text {
      width: 100%;
    }


    .payment-method-title {
      .label {
        @include font-DIN-next-bold(remcalc(20px));

        span {
          float: left;

          width: calc(100% - 60px);

          padding-top: 10px;

          white-space: normal;
        }
      }
    }


    .payment-method-content {
      padding: 0;
    }

    .checkout-agreements-block {
      input[type="checkbox"] {
        display: block !important;
        position: absolute;
        left: -999em;
        z-index: -100;
      }
    }

    .actions-toolbar.checkout-actions-toolbar,
    .checkout-agreements-block,
    .payment-method-billing-address {
      @include clearfix();

      @include breakpoint(sm) {
        @include make-row();

        margin-left: 60px !important;
      }


      .checkout-agreements {
        @include make-xs-column(12);


        .checkout-agreement {
          margin-top: 15px;

          &:first-of-type {
            margin-top: 0;
          }

          .styled {
            display: block !important;
            position: absolute;
            left: -999em;
            z-index: -100;
          }


          label {
            button {
              float: left;

              width: calc(100% - 60px);

              color: $link-color;
              text-align: left;
              white-space: normal;

              @include touch-hover-fix() {
                color: $link-hover-color;
              }
            }
          }
        }
      }


      > .fieldset {
        clear: both;

        width: 100%;


        .field-select-billing {
          margin-bottom: 0;
        }


        .billing-address-form {
          max-width: 100%;


          > form {
            margin-right: -15px;

            .fieldset {
              width: 100%;
            }
          }


          #billing-new-address-form {
            width: 100%;

            padding-left: 0;
            padding-right: 0;

            @media (max-width: $screen-sm-min) {
              @include make-xs-column(12);
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }

      .billing-address-details {
        @include font-DIN-next-light(remcalc(18px));
        color: $MAIN_COLOR;
        margin-left: 15px;
        margin-bottom: 20px;
      }
    }

    .payment-method-content > p,
    .payment-method-billing-address + p {
      margin-left: 75px;
      margin-bottom: 28px;
    }


    .actions-toolbar {
      .primary {
        width: 100%;


        .action-update {
          width: 50%;
        }


        .action-cancel {
          float: left;
        }
      }
    }


    .checkout-actions-toolbar {
      margin-bottom: 30px;


      .primary {
        @include make-xs-column(12);


        .action.checkout {
          @include make-sm-column(6);
          border-radius: 0;
          height: remcalc(70px);

          span {
            @include font-DIN-next-bold(remcalc(16px));
          }
        }
      }


      .ssl-note {
        width: 100%;

        margin-top: 20px;


        p {
          text-align: left;

          padding-top: 5px;

          @include breakpoint(md) {
            padding-top: 10px;
          }
        }
      }
    }
  }


  .billing-address-toolbar.actions-toolbar {
    @include make-row();
    border-top: 1px solid #ccc;
    margin-bottom: 30px;
    margin-left: 15px !important;
    margin-right: 0;
    padding-top: 20px;

    > .primary {
      @include make-xs-column(12);
      @include make-sm-column(6);
      padding-left: 0;
      padding-right: 0;

      &:nth-child(1) {
        @include make-sm-column-push(6);

        @include breakpoint(xs-only) {
          margin-top: 50px;
        }
      }

      &:nth-child(2) {
        @include make-sm-column-pull(6);

        @include breakpoint(xs-only) {
          margin-top: -110px;
        }

        margin-left: -15px;
      }


      .action-update {
        @include font-DIN-next-bold(inherit, inherit);
        width: 100%;
      }


      .action-cancel {
        font-size: $BUTTON_TEXT_SIZE_SM_UP;
        line-height: 40px;
      }
    }
  }


  .checkout-agreements-block .action-show,
  .agreements-block .action-show {
    @include font-DIN-next-regular(remcalc(16px));
    color: $MAIN_COLOR;
  }


  div.mage-error[generated] {
    @include font-DIN-next-regular(remcalc(18px));
    color: $SECONDARY_LIGHT_COLOR;
  }


  .field._error .control input,
  .field._error .control select,
  .field._error .control textarea {
    border-color: $SECONDARY_LIGHT_COLOR;
  }


  .abs-action-remove,
  .cart.table-wrapper .action.help.map,
  .opc-wrapper .edit-address-link,
  .opc-block-shipping-information .shipping-information-title .action-edit,
  .action-auth-toggle,
  .checkout-payment-method .payment-method-billing-address .billing-address-details .action-edit-address,
  .checkout-agreements-block .action-show,
  .cart-summary .block.giftcard .action.check,
  .table-comparison .wishlist.split.button > .action,
  .product-items .wishlist.split.button > .action,
  .paypal-button-widget .paypal-button,
  .abs-add-fields .action.remove,
  .form-giftregistry-share .action.remove,
  .form-giftregistry-edit .action.remove,
  .form-add-invitations .action.remove,
  .form-create-return .action.remove,
  .form.send.friend .action.remove {
    color: $MAIN_COLOR;
  }

  .checkout-payment-method .payment-method-billing-address {
    .billing-address-details {
      @include font-DIN-next-light(remcalc(18px));
      color: $MAIN_COLOR;

      .action-edit-address {
        @include font-DIN-next-light(remcalc(18px));
        background-color: transparent;
        border: 0;
        color: $SECONDARY_LIGHT_COLOR;
        padding: 0;
        padding-top: 15px;
        text-transform: lowercase;

        &:hover {
          color: #850010;
          text-decoration: underline;
        }
      }
    }
  }

  .checkout-payment-method .payment-method-billing-address .action-cancel,
  .gift-options .actions-toolbar .action-cancel {
    background-color: transparent;
    border: 0;
    color: $link-color;

    @include touch-hover-fix() {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }
}

@include breakpoint(sm) {

  .abs-margin-for-forms-desktop,
  .fieldset .legend,
  .column:not(.sidebar-main) form .actions-toolbar,
  .column:not(.sidebar-additional) form .actions-toolbar,
  .login-container .fieldset:after {
    margin-left: 0 !important;
  }
}


#checkoutSteps {
  label {
    color: $MAIN_COLOR;
  }
}


.checkout-onepage-success {
  #maincontent {
    .page-title-wrapper {
      position: relative;

      width: auto;
      height: auto;

      margin-right: -15px;
      margin-left: -15px;
    }


    .btn {
      padding-top: 10px;

      @include font-DIN-next-bold(remcalc(16px));
    }
  }
}


.minicart-items-wrapper {
  .details-qty {
    span.label {
      margin-right: 10px;
    }
  }
}

.checkout-payment-method .payment-method-title .payment-icon.paypal-logo {
  vertical-align: baseline;
}

.payment-method .payment-method-content .fieldset.paypal {
  display: inline;
  margin-left: 80px;
}


.modals-wrapper {
  .modal-popup.agreements-modal {
    .modal-inner-wrap {
      @include breakpoint(md) {
        height: 100%;
      }

      .modal-header {
        .action-close {
          height: 100%;
        }
      }


      .modal-footer {
        .action.secondary.action-hide-popup {
          @extend .btn;
          @extend .btn-primary;
        }
      }
    }
  }
}

.paypal-express-review {
  .page-title-wrapper .page-title {
    margin-top: 70px;
    @media (max-width: 800px) {
      margin-top: 90px;
    }
  }

  .block.block-order-details-view .block-content {
    .box-title span {
      font-size: 1.11111rem;
      font-weight: 400;
    }

    .box-order-shipping-method {
      display: none;
    }

    #shipping-method-form {
      display: none;
    }

    .box.box-order-shipping-address {
      padding-left: 0;
    }

    .box-content {
      font-family: 'DIN Next W02 Light', sans-serif;
      font-size: 1em;
      font-weight: lighter;
    }

    .box.box-order-billing-address {
      .box-content img {
        margin: 15px;
        margin-bottom: 0px;
      }

      .box-actions {
        margin-top: 15px;
      }
    }
  }

  .fieldset.coupon label.label {
    width: auto;
    margin-left: 5px;
  }

  .paypal-review-title {
    strong {
      text-transform: uppercase;
    }

    a {
      margin-left: 15px;
    }

    @media (max-width: $screen-sm) {
      strong {
        margin-bottom: 0.5rem;
      }
      display: grid;
      a {
        margin-bottom: 0.5rem;

        margin-left: 0;
      }
    }
  }
}

.custom-agreement-info {
  margin: 0 0 10px 75px;
}

.btn.action.apply.primary, .btn.action.apply.primary:hover {
  background-color: #ffffff;
  color: $gray-base;
  border: 1px solid $brand-primary;
}

.paypal-review-items {
  .custom-agreement-info {
    margin: 35px 0 0 0;
    @media (min-width: 800px) {
      width: 50%;
    }
  }

  .paypal-review-title {
    border-bottom: none;
  }

  .cart.items.data.table.table-paypal-review-items {
    thead tr th {
      font-weight: 400;
      font-size: 20px;
      text-transform: uppercase;
      border: none;
    }

    .cart.item {
      .col.item {
        border: none;
      }

      .col.qty {
        line-height: 2.3;
      }

      .cart-price {
        font-size: 1rem;
      }
    }

    .opc-block-summary {
      tr, th, td {
        font-weight: normal;
        border: none;
        background-color: #ffffff;
      }

      .totals.sub th {
        font-weight: 100;
        text-transform: uppercase;
      }

      .grand.totals {
        .mark strong, .amount strong {
          color: $brand-primary;
          text-transform: uppercase;
          font-size: 1.2rem;
        }

        .mark strong {
          font-weight: 400;
        }
      }
    }

    .opc-block-summary tr th {
      @media (max-width: $screen-sm) {
        padding: 10px;
      }
    }

    .opc-block-summary tr td {
      @media (min-width: $screen-sm) {
        display: block;
        width: 300%;
      }
      @media (max-width: $screen-sm) {
        padding: 10px;
      }
    }
  }

  tfoot.opc-block-summary tr th {
    width: 200%;
  }

  @media (max-width: 663px) {
    tfoot.opc-block-summary {
      display: block;
      background-color: #ffffff;
      margin-bottom: 0;
      padding: 20px 0;
    }
  }
  @media (max-width: $screen-sm) {

    .cart.item {
      tr .col.price, tr .col.qty, tr .col.subtotal {
        border: none;

        th {
          padding-left: 0 !important;
        }
      }

      .col.qty {
        line-height: 20px !important;
      }
    }
  }

  .checkout-agreements {

    .paypalcontainer {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .paypalcontainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 40px;
      width: 40px;
      z-index: 9999;
      left: 0;
    }

    /* Create a custom checkbox */
    .paypalcheckmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      width: 40px;
      background-color: white;
      border: 1px solid $MAIN_LIGHT_COLOR;
    }


    /* Create the checkmark/indicator (hidden when not checked) */
    .paypalcheckmark::after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .paypalcontainer input[type=checkbox]:checked ~ .paypalcheckmark::after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .paypalcontainer .paypalcheckmark::after {
      left: 14px;
      top: 7px;
      width: 11px;
      height: 20px;
      border: solid $CHECKMARK_GREEN;
      border-width: 0 4px 4px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    label {
      margin-left: 20px;

      button span {
        color: $SECONDARY_LIGHT_COLOR;
        font-family: 'DIN Next W02 Regular', sans-serif;
        font-size: .8889rem;
        line-height: 1.2;
        font-weight: normal;
      }

      button:hover {
        text-decoration: none;
      }
    }

  }


  .btn.btn-primary.checkout.action.checkout.primary {
    height: 3.8rem;
    opacity: 1;
    padding: 15px;
    @media (min-width: $screen-sm) {
      width: 330%;
    }

    span {
      line-height: 1.2rem;
      font-size: 18px;
      font-family: 'DIN Next W02 Bold', sans-serif;
    }

    .icon-arrow_right {
      margin-left: 6px;
      font-size: 14px;
    }
  }
}


@media (max-width: 700px) {
  th.col.item {
    span {
      font-family: 'DIN Next W02 Regular', sans-serif;
      font-size: 1.11111rem;
      line-height: 1.2;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  table.table-paypal-review-items {
    .cart.item {
      .col.item {
        display: inline-flex;
        float: none;
        width: 100% !important;
        padding-left: 0;

      }

      .product-item-name {
        color: $SECONDARY_LIGHT_COLOR;
      }

      .product-item-photo {
        position: static;
        width: 80px;
        max-width: 80px;
        padding-right: 12px;
      }

      .product-item-details {
        width: calc(100% - 92px);
      }

      .col.delivery-time, .col.price, .col.qty {
        text-align: left;
        border-top: 0;
        float: left;
        width: 33.33%;

        &:before {
          font-family: 'DIN Next W02 Light', sans-serif;
          font-size: 15px;
          line-height: 1.2;
          font-weight: lighter;
          text-align: left;
          text-transform: uppercase;
        }
      }

      .col.price .price-including-tax {
        line-height: 1rem;
      }

      .col.delivery-time:before {
        content: attr(data-th) ':';
        display: block;
        padding-bottom: 10px;
      }

      .col.subtotal {
        overflow: hidden;
        width: 100%;
        font-family: 'DIN Next W02 Light', sans-serif;
        font-size: 15px;
        line-height: 1.2;
        font-weight: lighter;
        text-align: left;
        text-transform: uppercase;

        &:before {
          float: left;
          font-weight: lighter;
        }

        .price-including-tax {
          float: right;
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}

// Dibs Easy checkout
.btn.btn-primary.action.checkout.dibs-easy-checkout {
  margin-top: 15px;
  line-height: 2;
  padding: 22px 100px;
  float: left;
  height: 3.88889rem;

  span {
    font-weight: 400;
  }
}

#dibs_easy_checkoutSidebar .checkout-success {
  .dibs-easy-checkout-order-overview.dibs-easy-checkout-thankyou-order-details.order_details {
    border-top: 6px solid $SECONDARY_LIGHT_COLOR;
  }

  div.dibs-easy-checkout-order__items {
    border: 3px solid $SECONDARY_LIGHT_COLOR;
  }

  .actions-toolbar .dibs-primary-wrapper .action.dibs-primary.dibs-btn.continue {
    background-image: linear-gradient(0deg, $SECONDARY_LIGHT_COLOR 0%, $SECONDARY_LIGHT_COLOR 99%) !important;
  }
}

//cart page
#block-discount {
  .title #block-discount-heading {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .content {
    margin-bottom: 15px;
    border-bottom: 1px solid #d1d1d1;

    .fieldset.coupon {

      margin: 15px 0 10px 0;
      display: flex;

      .field {
        min-width: 331px;

        #coupon_code {
          height: 41px;
        }
      }

      .actions-toolbar {
        width: 100%;
        float: right;

        .primary {
          float: right;

          .primary {
            margin: 0;
          }
        }
      }
    }
  }
}

//payment page
#checkout.checkout-container .opc-wrapper {
  .discount-code {
    .payment-option-title {
      width: 100%;
      border: 0;
      padding-top: 20px;
      padding-left: 0;
      margin-bottom: 0;

      span.action {

        color: $MAIN_COLOR;
        font-family: 'DIN Next W02 Regular', sans-serif;
        font-size: 1.11111rem;
        line-height: 1.2;
        font-weight: normal;
        text-transform: uppercase;
      }
    }

    .payment-option-content {
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
      padding: 0;
    }

    .form.form-discount {
      max-width: 600px;
      min-height: 120px;
      @media (max-width: 786px) {
        min-height: 190px;
      }

      .field {
        padding: 0;
        width: 65%;
      }

      .actions-toolbar {
        float: right;

        .action.action-apply {
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }
}