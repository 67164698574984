.minicart-weltpixel {
  .mage-dropdown-dialog {
    display: block !important;
  }

  &.minicart-wrapper {
    margin-left: 25px;

    .block-minicart {
      z-index: 1001;
    }
  }
}

@mixin box-sized {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.content {
  .quickcart-wrapper .block-quickcart {
    border-top: 0 none;
    border-bottom: 0 none;
    height: 100%;
    min-width: inherit;
    overflow: hidden;
    padding: 0;
    position: fixed !important;
    right: -580px !important;
    top: -4px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0 20px 50px -20px rgba(0, 0, 0, .5);
    @include box-sized();

    .quickcart-content-wrapper {
      width: 100%;
    }

    .block-content-quickcart {
      width: 100%;

      .quickcart-top {
        min-height: 30px;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: $gray-base;
        color: #fff;

        .text {
          text-transform: uppercase;
          color: #fff;
        }

        @include box-sized();


        button.close {
          min-height: 30px;
          min-width: 54px;
          right: 0;
          margin: 0;
          padding: 0;
          color: #fff;
          opacity: 1;

          &:before {
            line-height: unset;
            color: #fff;
            position: relative;
            font-size: 2.4rem;
          }

          > span {
            display: none;
          }
        }
      }

      .quickcart-items-wrapper {
        border: 0 none;
        height: calc(100% - 400px) !important;
        margin: 0;
        z-index: -1;
        position: absolute;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar-track {
          background-color: $well-bg;
        }

        &::-webkit-scrollbar,
        &::-moz-scrollbar {
          width: 6px;
          background-color: $well-bg;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c2c2c2;
        }

        .quickcart-items {

          .product.actions {
            margin: -45px 0 0;
            float: right;
          }

          .quickcart-product-item {
            padding-bottom: 0;


            .product-image-photo {
              border: 1px solid $gray-light;
            }

            .product-item-details {
              padding-left: 100px;
              padding-bottom: 15px;
            }

            .product-item-name {
              font-family: 'DIN Next W02 Regular', sans-serif;
              font-size: 1.11111rem;
              line-height: 1.2;
              font-weight: 400;
              padding-right: 30px;
              color: $brand-primary;
              max-width: 90%;

              & a {
                color: $brand-primary;
              }
            }

            .product-image-wrapper {
              margin-bottom: 10px;
            }

            .action.delete {
              span {
                background: transparent;
                display: block;
                font-size: 16px;
                height: 15px;
                margin: 0;
                position: relative;
                padding: 0;
                top: 0;
                width: 15px;
              }

              &:before {
                content: '\e616';
              }
            }

            .details-qty {
              margin-top: 5px;

              .label {
                font-size: 1rem;
              }

              .spinner {
                display: none;
                width: 10px;
                height: 10px;
                background-color: transparent;
                margin: 4px 12px 4px 13px;
                float: left;
                border: 1px solid #000;
                border-radius: 50%;
                border-bottom: 0 none;
                border-top: 0 none;
                top: -2px;
                position: relative;
                -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
                animation: sk-rotateplane 1.2s infinite ease-in-out;
              }

              @-webkit-keyframes sk-rotateplane {
                0% {
                  -webkit-transform: rotate(0deg)
                }
                50% {
                  -webkit-transform: rotate(-180deg)
                }
                100% {
                  -webkit-transform: rotate(360deg)
                }
              }

              @keyframes sk-rotateplane {
                0% {
                  transform: rotate(0deg);
                  -webkit-transform: rotateX(0deg);
                }
                50% {
                  transform: rotate(-180deg);
                  -webkit-transform: rotate(-180deg)
                }
                100% {
                  transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                }
              }
            }

            .price-container {
              float: right;
              margin-top: 5px;

              span {
                margin: 0;

                span.price-including-tax {
                  width: 100%;
                  display: table;
                  text-align: right;
                  line-height: .5;
                }

                span.price-excluding-tax {
                  width: 100%;
                }
              }

              span.price {
                font-weight: 700;
                font-size: 1rem;
                float: right;
                min-width: 80px;
              }

            }

            .options {
              margin: 0;
              min-height: 18px;
              padding-right: 15px;

              .option-wrapper {
                float: left;
                margin-top: 15px;
                margin-bottom: 15px;
                width: 100%;

                dt.label,
                dd.values {
                  float: left;
                  margin: 0 5px 0 0;
                }

                dt.label {
                  float: left;
                  margin-right: .5em;
                  font-family: 'DIN Next W02 Regular', sans-serif;
                  font-size: 1rem;
                  line-height: 1.44444rem;
                  font-weight: normal;
                  margin-bottom: 0;
                }

                dd.values {
                  float: unset;
                  font-family: 'DIN Next W02 Regular', sans-serif;
                  font-size: 1rem;
                  line-height: 1.44444rem;
                  text-align: right;
                  width: 100%;
                  margin-bottom: 0 !important;
                  margin: 0;
                }
              }
            }

            .product-item-pricing {
              margin-bottom: 50px;
              width: 100%;

              .price-wrapper {
                margin-bottom: 10px;

                .price-including-tax {
                  margin-bottom: 6px;
                }
              }

              .price-label {
                text-transform: uppercase;
              }

              .price-tax-hint {
                display: block;
                font-size: 14px;
              }
            }

            .product-item-pricing .label {
              float: left;
              padding: 0 20px 0 0;
              width: auto;

            }

            .details-qty.qty {
              margin-bottom: 5px;

              .label {
                margin-bottom: 0px;
                line-height: 1.4;
              }
            }

            span.qty-update {
              color: #000;
              font-size: 13px;
              float: left;
              padding: 0px;

              &.item-minus {
                transform: rotate(180deg);

                &:after {
                  @extend .dolle-icon;
                  content: $icon-arrow_right_mini;
                  font-size: remcalc(14px);
                  color: $text-color;
                  padding-left: 10px;
                }

              }


              &.item-plus {
                @media (max-width: $screen-xs) {
                  line-height: 1.9;
                }
                line-height: 2.2;

                &:after {
                  @extend .dolle-icon;
                  content: $icon-arrow_right_mini;
                  font-size: remcalc(14px);
                  color: $text-color;
                  padding-left: 10px;

                }
              }
            }
          }

          .cart-item-qty {
            color: $gray-dark;
            border: 0 none;
            float: left;
            height: auto;
            line-height: 25px;
            margin: 0;
            opacity: 1;
            padding: 0;
            text-align: center;
            font-size: 18px;
            width: auto;
            max-width: 35px;
            background: white;
            box-shadow: none;
            font-family: 'DIN Next W02 Bold', sans-serif;

          }

          button.update-cart-item {
            display: none;
          }
        }

        .quickcart-product-item:last-child {
          border-bottom: 1px solid #ccc;
        }
      }
    }

    .quickcart-bottom-container {
      bottom: 0;
      position: absolute;
      width: 100%;
      padding-bottom: 55px; // chat icon overflow

      .items-total {
        display: none;
      }

      .subtotal {
        margin: 0;
        padding: 20px 20px 18px;
        width: 100%;
        background: $MAIN_LIGHT_COLOR;

        span.label {
          line-height: 1.2;
          text-transform: uppercase;
          float: left;

          span {
            font-size: 16px;
            vertical-align: bottom;
          }
        }

        .price-container {
          .price-label {
            line-height: 1.7;
          }

          span.price {
            color: $gray-base;
            font-size: 18px;
          }
        }
      }

      .actions.action-viewcart {
        background: transparent;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;

        .secondary {
          padding: 15px;
        }
      }

      .actions.action-checkout {
        margin: 15px 0 0 0;
        padding: 0 10px;
        width: 100%;

        #top-cart-btn-checkout {
          display: block;
          margin: 0 auto 10px;
          min-width: 200px;
          padding: 10px 0;
          width: 100%;
          background-color: $brand-primary;
          border-color: $border;

          text-transform: uppercase;

          &:after {
            @extend .dolle-icon;
            content: $icon-arrow_right;
            font-size: remcalc(14px);
          }
        }

        .item-method-info {
          display: block;
          text-align: center;
          margin-top: 0;

          em {
            display: block;
            margin: 10px auto;
            color: $PAYMENT_LABEL_COLOR;
          }

          &.info-text {
            @include font-DIN-next-light(remcalc(18px));
            margin-top: 20px;

            strong {
              @include font-DIN-next-bold(remcalc(18px));
            }
          }
        }
      }

      .minicart-widgets {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.quickcart-wrapper.active .block-quickcart {
  right: 0 !important;
}

.darkwrapper {
  transition: all .3s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.9);
  z-index: 10;
}

.minicart-wrapper.quickcart-wrapper.minicart-weltpixel .block-minicart {
  width: 580px;
  @media (max-width: $screen-xs) {
    width: 350px;
    max-width: 100%;
  }
}

