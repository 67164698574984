#spare-parts-searchform {
  margin: 1rem 0;

  .col-sm-6 {
    padding: 0;
  }

  input[type=text] {
    height: 40px;
  }

  .mage-error {
    font-size: inherit;
  }

  #tooltip {
    margin-top: 5px;
    margin-right: 20px;
  }

  .field-tooltip .field-tooltip-content {
    width: 500px;
  }

  #reset-spare-part-search {
    display: inline-block;
    line-height: 40px;
    margin: 0 1rem;
  }

  @media (max-width: $screen-sm) {
    button {
      margin-top: 1rem;
    }
    .field-tooltip .field-tooltip-content {
      width: 270px;
    }
  }
}
