.catalog-product-view {
  .module-breadcrumb {
    margin-top: 0;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }

  .fotorama__fullscreen-icon:focus,
  .fotorama__zoom-out:focus,
  .fotorama__zoom-in:focus,
  .fotorama__arr:focus,
  .fotorama__stage__shaft:focus,
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    &:after {
      box-shadow: none;
    }
  }

  .fotorama__nav.fotorama__shadows--right, .fotorama__nav.fotorama__shadows--left {
    &:after, &:before {
      background-image: none;
    }
  }

  .product.media {
    .uc-embedding-container {
      z-index: 9;
    }
  }

  .columns {
    .column.main {
      .product-info-main,
      .product.media,
      .product-info-bundle-main {
        @include make-sm-column(6);
        margin-top: 0;

        .fotorama__stage__frame {
          border: 1px solid #FFF;


          .fotorama__caption {
            display: none;
          }
        }

        .fotorama-item {
          @include breakpoint(md) {
            width: calc(100% - 35px) !important;
          }

          .fotorama__stage {
            @include breakpoint(md) {
              margin-bottom: 15px;
            }
          }

          .fotorama__stage, .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
            .fotorama__stage__shaft {
              width: 100% !important;
              border: 1px solid $MAIN_BORDER_COLOR;
            }
          }

          .fotorama__thumb__arr {
            height: 110px;
            top: auto;
            bottom: 0;
          }

          .fotorama__nav-wrap {
            @include breakpoint(md) {
              margin: 0 auto;
            }

            .fotorama__nav--thumbs {
              height: auto !important;
            }

            .fotorama__nav__frame.fotorama__nav__frame--thumb {
              box-sizing: border-box;
              border: 0 none;

              &:first-of-type, &:last-of-type {
                padding-left: 0 !important;
              }
            }

            .fotorama__thumb-border {
              width: 100% !important;
              display: none !important;
            }

            .fotorama__thumb {
              background-color: transparent;
              border: 1px solid transparent;
            }

            .fotorama__active {
              .fotorama__thumb {
                border-color: $SECONDARY_LIGHT_COLOR !important;
              }
            }
          }

          .fotorama__nav.fotorama__nav--thumbs {
            @extend .hidden-xs;
          }
        }

        .new, .promotion {
          position: absolute;
          top: 3px;
          padding: 0 10px;
          right: 4.8%;
          @include breakpoint(md) {
            right: 9.21%;
          }
          @include font-DIN-next-regular(remcalc(20px), remcalc(30px));
          @include breakpoint(sm) {
            @include font-DIN-next-regular(remcalc(20px), remcalc(34px));
          }
        }

        .new {
          color: #FFF;
          background-color: $SECONDARY_LIGHT_COLOR;
        }

        .promotion {
          color: $MAIN_COLOR;
          background-color: $MAIN_LIGHT_COLOR;
          @include font-DIN-next-bold(remcalc(20px), remcalc(34px));

          > span {
            @include font-DIN-next-regular(remcalc(20px), remcalc(34px));
            color: $SECONDARY_LIGHT_COLOR;
          }
        }

      }

      .subtitle {
        text-align: unset;
        margin-top: 0;
        @include font-DIN-next-medium(remcalc(18px));
        color: $SECONDARY_LIGHT_COLOR;
      }


      .product-info-main, .product-info-bundle-main {
        float: right;

        .page-title-wrapper .page-title {
          line-height: 1;
          margin-bottom: 0;
        }

        .subtitle {
          margin-top: 10px;
          @include font-DIN-next-medium(remcalc(18px));
          color: $SECONDARY_LIGHT_COLOR;
        }


        .shortdescription-wrapper {
          padding: 20px 0 25px 0;

          p {
            margin: 0;
          }
        }

        .price-box {
          margin-top: 0;

          .price-container.price-final_price {
            dt {
              @include font-DIN-next-bold(remcalc(20px), 1);
            }

            .price {
              @include font-DIN-next-bold(remcalc(24px), 1);
              color: $SECONDARY_LIGHT_COLOR;
            }
          }

          .price-label {
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;
          }
        }

        .delivery_time {
          display: none;
        }

        /* exists only on simple products */
        .product-info-price {
          h4 {
            padding-top: 30px;
            border-top: 1px solid $MAIN_BORDER_COLOR;
            margin-top: 0;

            color: $SECONDARY_LIGHT_COLOR;
          }

          .product-attributes {
            /* border top needs to be on h4 */
            border-bottom: 1px solid $MAIN_BORDER_COLOR;
            margin-bottom: 20px;
            padding-bottom: 30px;
          }
        }


        .delivery_time-wrapper {
          float: left;

          width: calc(100% - 20px);

          margin: 0;
          padding-right: 5px;

          .delivery-placeholder {
            position: relative;
            margin-right: -25px;
          }
        }


        .stock-wrapper {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          float: right;

          > span {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 100%;

            &.red {
              background-color: $SECONDARY_LIGHT_COLOR;
            }

            &.green {
              background-color: green;
            }

            &.yellow {
              background-color: #fce75d;
            }
          }

          & ~ .product-info-stock-sku {
            clear: both;
          }
        }


        .product-info-stock-sku {
          // yes it's attibute, thanks magento
          .product.attibute.sku {
            @include clearfix();

            width: 100%;

            .type {
              float: left;

              margin-right: 8px;

              @include font-DIN-next-regular(remcalc(16px), remcalc(26px));
              color: $MAIN_COLOR;
            }

            .value {
              float: right;

              @include font-DIN-next-bold(remcalc(16px), remcalc(26px));
              color: $MAIN_COLOR;
            }
          }
        }


        .product-add-form {
          width: 100%;


          .product-options-wrapper {
            padding: 30px 0;
            padding-top: 10px;
            //border-top: 1px solid $MAIN_BORDER_COLOR;
            border-bottom: 1px solid $MAIN_BORDER_COLOR;
            margin-bottom: 20px;


            > h4 {
              margin: 0 0 10px;

              color: $SECONDARY_LIGHT_COLOR;
            }

            .configurable {
              &.field {
                label > span {
                  @include font-DIN-next-regular(remcalc(16px), remcalc(26px));
                }
              }
            }

            .swatch-opt, .bundle-configurable {
              margin-bottom: 0;

              .swatch-attribute {
                margin-top: 0;
                margin-bottom: 15px;

                .swatch-attribute-label {
                  @include font-DIN-next-regular(remcalc(16px), remcalc(26px));
                }

                .swatch-attribute-selected-option {
                  @include font-DIN-next-bold(remcalc(16px), remcalc(26px));

                  float: right;

                  color: $MAIN_COLOR;
                }

                .swatch-attribute-options {
                  margin-top: 5px;

                  .swatch-option {
                    outline: none;
                    overflow: hidden;

                    width: 60px;
                    height: 40px;

                    border: 1px solid $MAIN_BORDER_COLOR;

                    background: transparent;

                    &:hover,
                    &:focus {
                      border-color: $MAIN_COLOR;
                    }

                    &.selected {
                      @include font-DIN-next-bold(remcalc(16px), 40px);
                      border-color: $SECONDARY_LIGHT_COLOR;
                    }

                    &.text {
                      width: 80px;

                      padding: 0;

                      @include font-DIN-next-regular(remcalc(16px), 40px);
                      text-align: center;
                    }

                    &.image {
                      width: 60px;

                      padding: 1px;
                    }
                  }

                }

                .mage-error {
                  color: $SECONDARY_LIGHT_COLOR;
                  font-size: remcalc(14px);
                }
              }

              &:first-of-type {
                margin-top: 0;
              }
            }

            .bundle-configurable {
              .swatch-attribute {
                margin-bottom: 0;
              }
            }
          }


          .box-tocart {
            padding: 30px;
            margin: 20px 0 0 0;
            width: 100%;

            background-color: $BOX_BG_COLOR_LIGHT;


            .field.qty {
              display: table-cell;


              label {
                display: none;
              }


              .input-text.qty {
                border-style: solid;
                border-width: 1px;
                border-color: $BG_COLOR;
                box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.1);
                width: 48px;
                height: 40px;
              }
            }


            .actions {
              padding: 0;
              width: 100%;
              text-align: center;
              vertical-align: bottom;

              .btn {
                width: 100%;


                .icon-arrow_right {
                  margin-left: 6px;
                  font-size: 14px;
                }
              }

              @media screen and (min-width: 576px) {
                display: table-cell;
              }
              @media screen and (max-width: 575px) {
                margin-top: 15px;
                .btn.btn-primary.button:not(.tocart) span {
                  font-size: .85rem !important;
                }
              }
            }


            button,
            .btn-primary, .btn-gray {
              margin-top: 10px;

              &:first-child {
                margin-top: 0;
              }


              > span {
                @include font-DIN-next-bold(remcalc(16px));
              }
            }


            &.not-salable {
              .actions {
                display: block;
              }

              .field.qty + .actions {
                @media screen and (min-width: 576px) {
                  display: table-cell;
                }
                @media screen and (max-width: 575px) {
                  margin-top: 15px;
                }
              }
            }
          }
        }


        .price-final_price-wrapper {
          margin-top: 20px;

          dt {
            @include font-DIN-next-bold(remcalc(20px));
          }

          dd {
            @include font-DIN-next-bold(remcalc(24px));
          }
        }
      }

      .product.data.items {
        .all-products-btn {
          background: none;
          border: none;
          color: $MAIN_COLOR;

          .icon {
            padding-left: 10px;
            color: $SECONDARY_LIGHT_COLOR;
            @include font-DIN-next-light(remcalc(12px), remcalc(42px));
          }
        }

        > .item.title {
          @media (max-width: $screen-xs-max) {
            a {
              display: block;

              position: relative;

              padding: 0;
              border: 0;
              border-top: 1px solid $MAIN_BORDER_COLOR;
              outline: none !important;

              background-color: transparent;

              @include font-DIN-next-regular(remcalc(20px), remcalc(55px));
              color: $MAIN_COLOR;
              text-transform: uppercase;
              text-decoration: none !important;

              &:after {
                content: $icon-arrow_down;

                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'dolle-icons' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 0;
                font-size: 9px;
                line-height: remcalc(55px);
                color: $SECONDARY_LIGHT_COLOR;
              }

              &:hover {
                color: $SECONDARY_LIGHT_COLOR;
                text-decoration: none;
              }
            }

            &.active {
              a {
                &:after {
                  content: $icon-arrow_up;
                }
              }
            }
          }

          @include breakpoint(sm) {
            margin: 0 20px;

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }

            > a {
              border: 0;
              padding: 0;
              outline: none;
              height: auto;
              @include font-DIN-next-regular(remcalc(14px), remcalc(20px));
              background-color: transparent !important;
              text-transform: uppercase;
              padding-bottom: 10px;
            }
            &.active {
              > a {
                @include font-DIN-next-bold(remcalc(14px), remcalc(20px));
                color: $SECONDARY_LIGHT_COLOR;
                border-bottom: 5px solid $SECONDARY_LIGHT_COLOR;
              }
            }
          }
          @include breakpoint(lg) {
            margin: 0 25px;
            > a {
              @include font-DIN-next-regular(remcalc(20px), 1);
            }
            &.active {
              > a {
                @include font-DIN-next-bold(remcalc(20px), 1);
              }
            }
          }
        }

        > .item.content {
          visibility: hidden;
          overflow: hidden;

          height: 1px;

          &[aria-hidden="false"] {
            visibility: visible;
            overflow: visible;
            height: auto;
          }
        }

        .video-item {
          margin-bottom: 30px;
        }

        > .item.title.active {
          + [aria-hidden="true"] {
            visibility: visible;
            overflow: visible;
            height: auto;
          }
        }
      }


      .product.info.detailed,
      .product-subinfos {
        @include make-sm-column(12);
      }

      .product-subinfos {

        margin: 40px 0;

        .product-subinfos-wrapper {
          @include make-row();

          .product-subinfos-border {
            border-top: 1px solid $MAIN_BORDER_COLOR;
            border-bottom: 1px solid $MAIN_BORDER_COLOR;
            padding: 20px 0 10px;
            margin: 0 15px;
            @include clearfix();
          }

          .product-subinfos-col {
            @include make-sm-column(9);
          }

          .print-link-col {
            text-align: right;
            @include make-sm-column(3);
          }

          .product-subinfos-col,
          .print-link-col {
            padding: 0;
          }

        }
      }

      .product.info.detailed {
        .items {
          .item {
            #tab-label-mageworx_product_attachments-title {
              .counter {
                display: none;
              }
            }

            &.content {
              table {
                font-family: 'DIN Next W02 Light', sans-serif;
                font-size: 1rem;
                border-collapse: collapse;
                border-spacing: 0;
                border-color: #ccc;


                tr {
                  vertical-align: top;

                  td {
                    padding: 10px 19px;
                    border-style: solid;
                    border-width: 0;
                    overflow: hidden;
                    word-break: normal;
                    border-color: #ccc;
                    border-top-width: 1px;
                  }

                  th {
                    font-family: 'DIN Next W02 Regular', sans-serif;
                    font-weight: lighter;
                    padding: 10px 19px;
                    border-style: solid;
                    border-width: 0;
                    overflow: hidden;
                    word-break: normal;
                    border-color: #ccc;
                    background-color: #f0f0f0;
                    border-top-width: 1px;
                    border-bottom-width: 1px;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: 767px) {
          .tg-wrap {
            overflow-x: auto;
            webkit-overflow-scrolling: touch;
          }

          table {
            width: auto !important;

            col {
              width: auto !important;
            }
          }
        }
      }

      > .block.upsell, .block.crosssell {
        //@include make-row();
        @include clearfix();


        .block-content {
          .all-products-btn {
            background: none;
            border: none;
            color: $MAIN_COLOR;

            .icon {
              padding-left: 10px;
              color: $SECONDARY_LIGHT_COLOR;
              @include font-DIN-next-light(remcalc(12px), remcalc(42px));
            }
          }
        }

        .block-content,
        .block-title {
          @include make-xs-column(12);
        }
      }

      .product.data.items > .item.content {
        padding: 40px 0;
        border-bottom: 2px solid $MAIN_BORDER_COLOR;
        @media only screen and (max-width: 767px) {
          border: 0;
        }
      }

      .product-technical-data-container {
        @include gutterbased(margin-left, -0.5);
        @include gutterbased(margin-right, -0.5);

        .left {
          @include make-xs-column(12);
          @include make-sm-column(4);

          text-align: center;

          > img {
            width: 100%;
          }
        }

        .right {
          @include make-xs-column(12);
          @include make-sm-column(8);

          > .product-attributes-headline {
            margin-top: 0;
          }

          .product-attributes {

            dt {
              position: relative;

              > .technical-data-counter {
                position: absolute;

                top: 14px;
                left: 0;

                display: block;

                width: 24px;
                height: 24px;

                border-radius: 12px;

                line-height: 24px;
                text-align: center;

                color: $BUTTON_TEXT_COLOR;
                background-color: $SECONDARY_LIGHT_COLOR;
              }

              > .technical-data-label {
                padding-left: 40px;
                display: inline-block;
              }
            }

            dd {
              @include breakpoint(xs-only) {
                padding-left: 40px;
              }

            }

          }

          ol {
            width: 100%;

            padding: 0;
            margin: 0 auto;
            margin-bottom: remcalc(50px);

            @include font-DIN-next-regular(inherit, inherit);

            list-style: none;
            counter-reset: item;

            @include breakpoint(xs-only) {
              margin-top: remcalc(50px);
            }

            @include breakpoint(sm) {
              margin-right: 0;
              margin-left: 0;
            }


            > li {
              position: relative;

              min-height: 24px;

              padding-left: 44px;
              margin: 0 0 remcalc(20px) 0;

              counter-increment: item;


              &:before {
                content: counter(item);

                display: block;

                position: absolute;
                top: 0;
                left: 0;

                width: 24px;
                height: 24px;

                border-radius: 12px;

                line-height: 27px;
                text-align: center;

                color: $BUTTON_TEXT_COLOR;
                background-color: $SECONDARY_LIGHT_COLOR;
              }
            }
          }

          h2 {
            text-align: left;
          }

          dl {
            @include clearfix();


            dt {
              float: left;

              width: 100%;

              padding-top: remcalc(8px);
              padding-right: 0.5em;
              margin-right: 0;

              @include font-DIN-next-regular(remcalc(20px), remcalc(42px));
              text-align: left;
              text-transform: uppercase;

              &.divider {
                padding: 0;
                border-bottom: 1px solid $MAIN_BORDER_COLOR;

                > * {
                  display: none;
                }
              }


              @include breakpoint(sm) {
                width: 50%;

                padding: remcalc(8px) 0.5em remcalc(8px) 0;
                //border-bottom: 1px solid $MAIN_BORDER_COLOR;

                &.divider {
                  width: 99%;
                  padding: 0;
                }
              }


            }


            dd {
              float: left;

              width: 100%;

              padding-bottom: remcalc(8px);
              //border-bottom: 1px solid $MAIN_BORDER_COLOR;
              margin-top: remcalc(-8px);

              @include font-DIN-next-light(remcalc(20px), remcalc(42px));
              text-align: left;

              &.divider {
                display: none;

                padding: 0;
                border-bottom: 1px solid $MAIN_BORDER_COLOR;

                > * {
                  display: none;
                }
              }


              @include breakpoint(sm) {
                width: 50%;

                padding: remcalc(8px) 0;
                margin-top: 0;

                &.divider {
                  width: 1%;
                  padding: 0;
                }
              }
            }
          }
        }
      }

      .product-tested-data-container {
        @include make-xs-column(12);

        .tested-box {
          display: flex;
          align-items: center;
          //@include make-xs-column(6);
          //@include make-sm-column(4);
          float: left;
          width: calc(50% - 30px);
          @include breakpoint(sm) {
            width: calc(33.33333% - 30px);
          }
          margin: 0 15px 30px;
          height: 95px;
          padding: 10px 10px 10px 94px;
          border: 1px solid $MAIN_BORDER_COLOR;

          background-position: 10px center;
          background-repeat: no-repeat;

          &.u_value_ift {
            background-image: url("../img/tested/u_value_ift.jpg");
          }

          &.en_12207_class4 {
            background-image: url("../img/tested/en_12207_class4.jpg");
          }

          &.en_14975 {
            background-image: url("../img/tested/en_14975.jpg");
          }

          &.fire_tested_30 {
            background-image: url("../img/tested/fire_tested_30.jpg");
          }

          &.fire_tested_90 {
            background-image: url("../img/tested/fire_tested_90.jpg");
          }

          &.tuev {
            background-image: url("../img/tested/tuev.jpg");
          }

        }

        h4 {
          margin-left: 15px;
          margin-right: 15px;
        }
      }

      .product-detail-container {
        // keep distance if subsection follows long_description, which does not
        .description {
          margin-bottom: remcalc(50px);

          &:last-child {
            margin-bottom: 0;
          }

          @media (max-width: $screen-xs-max) {
            table {
              display: block;
              overflow-x: auto;
              white-space: nowrap;
            }
          }
        }


        ul {
          padding: 0;
          margin-bottom: remcalc(50px);

          columns: 1;
          column-gap: 1.66667rem;
          list-style-type: none;

          @include breakpoint(sm) {
            columns: 2;
          }


          li {
            break-inside: avoid; /* W3C */
            -webkit-column-break-inside: avoid; /* Safari & Chrome */
            -moz-column-break-inside: avoid; /* Firefox */
            -ms-column-break-inside: avoid; /* Internet Explorer */

            position: relative;
            margin-left: 30px;
            @include font-DIN-next-light(remcalc(18px), remcalc(42px));

            &:before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              margin-top: 16px;
              margin-right: 10px;
              border-radius: 100%;
              background-color: $MAIN_COLOR;
              position: absolute;
              left: -30px;
              top: 0;
            }
          }


          &.usps {
            li {
              &:before {
                width: 24px;
                height: 18px;

                margin-top: 8px;
                margin-right: 10px;
                border-radius: 0;

                background-color: transparent;
                background-image: url('../img/usp_bullet.svg');
                background-size: 24px 18px;
              }
            }
          }
        }


        .media {
          padding-left: 0;


          .slick-prev {
            top: 25px;
            left: 0;

            margin-top: 0;

            @include breakpoint(sm) {
              top: 35px;
            }

            @include breakpoint(md) {
              top: 55px;
            }

            @include breakpoint(lg) {
              top: 72px;
            }
          }


          .slick-next {
            top: 25px;
            right: 0;

            margin-top: 0;

            @include breakpoint(sm) {
              top: 35px;
            }

            @include breakpoint(md) {
              top: 55px;
            }

            @include breakpoint(lg) {
              top: 72px;
            }
          }


          .slick-list {
            margin: 0 remcalc(-28px) 0 remcalc(-30px);

            .slick-slide {
              cursor: pointer;


              .image-holder,
              .video-holder {
                overflow: hidden;
                position: relative;

                padding: 1px;
                border: 1px solid $MAIN_BORDER_COLOR;
                margin: 0 remcalc(15px);

                @include breakpoint(sm) {
                  margin: 0 remcalc(30px);
                }


                > .image-element {
                  position: absolute;
                  top: 1px;
                  right: 1px;
                  bottom: 1px;
                  left: 1px;

                  background-position: center center;
                  background-size: cover;
                }
              }

              .video-holder {
                position: relative;


                .playbutton {
                  position: absolute;
                  top: 50%;
                  left: 50%;

                  width: 52px;
                  height: 52px;

                  margin-top: -26px;
                  margin-left: -26px;

                  background-color: rgba(255, 255, 255, 0.75);

                  &:after {
                    content: '';

                    position: absolute;
                    top: 50%;
                    left: 50%;

                    width: 32px;
                    height: 26px;

                    margin-left: -6px;
                    margin-top: -13px;

                    border-style: solid;
                    border-color: transparent;
                    border-width: 13px;
                    border-left-color: $SECONDARY_LIGHT_COLOR;
                  }

                  @include breakpoint(xxs-only) {
                    width: 26px;
                    height: 26px;

                    margin-top: -13px;
                    margin-left: -13px;

                    &:after {
                      width: 16px;
                      height: 13px;

                      margin-left: -3px;
                      margin-top: -7px;

                      border-width: 7px;
                    }
                  }
                }
              }


              .label {
                display: block;

                margin: remcalc(15px) remcalc(30px) remcalc(30px) remcalc(30px);

                @include font-DIN-next-regular(remcalc(18px), 1);
                text-align: center;
                text-transform: uppercase;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }
}


.price-final_price-wrapper {
  margin-bottom: 0;

  dt, dd {
    @include font-DIN-next-bold(remcalc(16px));
    margin-bottom: 0;
  }
}


.block.upsell, .block.crosssell {
  clear: both;
  @include clearfix();
}


.product-attachment-container {
  @include make-row();

  padding: 0;


  .downloads-item {
    @include make-sm-column(6);

    margin-bottom: 18px;


    .item-icon {
      float: left;
      width: remcalc(32px) !important;
      height: remcalc(32px) !important;
      padding-top: 0 !important;
      font-size: remcalc(32px) !important;
      color: $SECONDARY_LIGHT_COLOR;
      @extend .icon-pdf_download;

      > img {
        display: none;
      }
    }


    .item-link {
      display: block;
      float: left;
      margin-top: 6px;
      margin-left: 10px;

      width: calc(100% - #{remcalc(32px)} - 10px);


      a {
        @include font-DIN-next-regular(remcalc(18px), remcalc(28px));
        color: $MAIN_COLOR;
        font-weight: normal;

        > * {
          font-weight: normal;
        }
      }
    }


    .item-info {
      display: block;
      float: left;

      width: 100%;

      padding-left: calc(#{remcalc(32px)} + 10px) !important;

      font-size: smaller;
    }


    .item-description {
      display: block;
      float: left;

      width: 100%;

      padding-left: calc(#{remcalc(32px)} + 10px) !important;

      font-size: smaller;
    }
  }
}


.fotorama__thumb-border {
  border-color: $SECONDARY_LIGHT_COLOR !important;
}


.swatch-option-tooltip {
  @include font-DIN-next-regular(remcalc(16px), remcalc(40px));
}

.catalog-product-view {
  #product-options-wrapper {
    .swatch-attribute-options .swatch-option {
      &.image {
        background-size: cover !important;
        height: 60px !important;
        width: 60px !important;
      }

      &.text {
        line-height: 1.5;
        padding: 3px 5px;
        height: auto;
        width: auto;
        min-width: 80px;
        max-width: 115px;
      }

      &.disabled {
        &:after {
          background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, rgba(255, 255, 255, 1) 43%, rgba(255, 255, 255, 1) 46%, rgba($SECONDARY_LIGHT_COLOR, 1) 47%, rgba($SECONDARY_LIGHT_COLOR, 1) 53%, rgba(255, 255, 255, 1) 54%, rgba(255, 255, 255, 1) 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }

    .swatch-select {
      .disabled {
        color: $GREY_DISABLED_OPTION !important;
      }
    }
  }

  #maincontent.content-eu {
    #product-options-wrapper {
      border-bottom: 0 none;
    }
  }

  .swatch-option-tooltip {
    border: 0 none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    max-width: unset;

    .image {
      background-size: cover !important;
      width: 100%;
      min-width: 120px;
      min-height: 120px;
    }

    .title {
      color: $MAIN_COLOR;
      max-height: 30px;
      max-width: 165px;
      padding: 2px 10px;
      line-height: 1.5;
      width: auto;
      overflow: visible;
      display: table;
      white-space: normal;
      margin: 0 auto;
    }

    .corner {
      width: 50px;
      height: 20px;
      position: absolute;
      transform: translateX(-50%);
      overflow: hidden;
      top: 100%;
      left: 50% !important;

      &:before {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #fff;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
        left: 50%;
      }
    }
  }

  .columns {
    .column.main {
      .bundle-options-container {
        .option-head {
          width: 100%;
          border-bottom: 1px solid $MAIN_BORDER_COLOR;
          margin: 5px 0 10px;
          padding-bottom: 5px;

          > label.label {
            text-transform: uppercase;
            @include font-DIN-next-bold(remcalc(18px), 1);
            color: $SECONDARY_LIGHT_COLOR;
          }

          &.qty-box {
            display: flex;
            justify-content: space-between;
          }
        }

        .qty-holder {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          label {
            margin-bottom: 0;
            margin-right: 8px;
          }

          input.qty {
            color: $gray-dark;
            border: 0 none;
            height: auto;
            line-height: 25px;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 18px;
            width: auto;
            max-width: 35px;
            background: white;
            box-shadow: none;
          }

          span.qty-update {
            font-size: 13px;
            padding: 0;
            cursor: pointer;

            &.increase-button, &.decrease-button {
              &:after {
                @extend .dolle-icon;
                font-size: remcalc(14px);
                color: $SECONDARY_LIGHT_COLOR;
              }
            }

            &.increase-button {
              &:after {
                content: $icon-arrow_right;
              }
            }

            &.decrease-button {
              &:after {
                content: $icon-arrow_left;
              }
            }
          }
        }

        .price-box.price-configured_price {
          margin-top: 20px;

          .price-label {
            position: static;
            overflow: auto;
            width: auto;
            height: auto;
            @include font-DIN-next-bold(remcalc(20px), 1);

            &:after {
              content: '*';
            }
          }

          .price-wrapper {
            @include font-DIN-next-bold(remcalc(24px), 1);
            color: $SECONDARY_LIGHT_COLOR;
            float: right;
          }
        }
      }

      .product-info-bundle-main {
        .product-info-main {
          float: none;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }

        .product-info-price {
          .price-box {
            display: none;
          }
        }
      }
    }
  }

  .bundle-options-container {
    .product-add-form {
      display: block !important;
    }

    .block-bundle-summary,
    .bundle-options-wrapper {
      float: none;
      width: 100%;
    }

    .block-bundle-summary {
      background: none;
      padding: 0;
    }

    .product-options-wrapper {
      width: 100%;

      p.required {
        margin-top: 10px;
      }
    }

    .label.checkbox {
      display: inline-block;
      width: 100%;
    }

    .highlighted {
      &.options-list .stylable-input:before, &.selectproxy {
        border: 1px solid $SECONDARY_LIGHT_COLOR;
        box-shadow: 1px 1px 10px 0 rgba($SECONDARY_LIGHT_COLOR, 0.2);
      }
    }

    .bundle-tooltip {
      position: relative;
      display: inline-block;
      cursor: pointer;

      .tooltip-content {
        display: none;
        border: 1px solid $INPUT_BORDER_COLOR;
        background-color: #fff;
        width: 135px;
        height: 135px;
        top: -135px;
        right: -135px;
        overflow: hidden;
        z-index: 1;

        img {
          display: block;
          margin: 0 auto;
        }
      }

      &:hover {
        .tooltip-content {
          display: block;
          position: absolute;
        }
      }
    }

    .swatch-attribute-selected-option {
      margin-top: 5px;
    }

    .bundle-configurable {
      &.fieldset {
        margin-left: 0;
        margin-top: 0;
      }

      div.mage-error[generated] {
        margin-top: 0;
        @include font-DIN-next-regular(remcalc(14px), 1);
      }
    }

    .bundle-item {
      margin-bottom: 10px;
    }

    .fieldset-bundle-options {
      margin-bottom: 0;

      .product-name, .price {
        @include font-DIN-next-regular(remcalc(16px), 1);
      }
    }

    .box-tocart .field.qty {
      padding-right: 15px;
    }
  }
}