body {
    .columns {
        @include make-row();
        background-color: $BODY_BG_COLOR;

        .column.main {
            @include make-xs-column(12);
        }
    }

    &.page-layout-2columns-left,
    &.page-layout-2columns-right {
        .columns {
            .column.main {
                @include make-xs-column(12);
                @include make-md-column(9);
                //float: right !important;
                //@include make-md-column-push(3);
            }

            .sidebar-additional,
            .sidebar-main {
                @include make-xs-column(12);
                @include make-md-column(3);
                //@include make-md-column-pull(9);
            }
        }
    }

    .content > .page-title-wrapper {
        position: relative;
        @include make-row();
        background-color: $BODY_BG_COLOR;

        h1 {
            @include make-xs-column(12);
        }
    }


    main.content {
        .columns {

            .action.primary,
            .btn.action,
            .button.action,
            a.button.action,
            button.button.action {
                @extend .btn-primary;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857;
            }

            .btn.action {
                > span {
                    @include font-DIN-next-regular(remcalc(18px));
                }

                > i.icon-arrow_right {
                    margin-left: 6px;
                    font-size: remcalc(14px);
                }
            }

            form {
                .field {
                    @extend .form-group;
                }

                .input-text {
                    @extend .form-control;
                }

                .label {
                    padding: 0;
                    font-family: 'DIN Next W02 Light', sans-serif;
                    font-size: 1rem;
                    line-height: 1.2;
                    color: $MAIN_COLOR;
                    margin-bottom: 5px;
                    border: 0;
                }
            }


            .action.primary,
            .btn.action,
            .button.action,
            a.button.action,
            button.button.action {
                @extend .btn-primary;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857;
            }

            .btn.action {
                > span {
                    @include font-DIN-next-regular(remcalc(18px));
                }

                > i.icon-arrow_right {
                    margin-left: 6px;
                    font-size: remcalc(14px);
                }
            }

            form {
                .field {
                    @extend .form-group;
                }

                .input-text {
                    @extend .form-control;
                }

                .label {
                    padding: 0;
                    font-family: 'DIN Next W01 Light', sans-serif;
                    font-size: 1rem;
                    line-height: 1.2;
                    color: $MAIN_COLOR;
                    margin-bottom: 5px;
                    border: 0;
                }
            }

        }
    }

    .label {
        display: inline;
        padding: 0;
        font-size: 100%;
        font-weight: normal;
        line-height: 1;
        color: $MAIN_COLOR;
        border-radius: 0;
    }

    .navbar.navbar-default.navbar-cart-search-wrapper {
        float: right;
        margin-right: 60px;
        margin-left: 0 !important;

        .navbar-cart-search {
            .icon-x_icon {
                float: right !important;
            }
        }

        @media (max-width: $screen-lg) {
            margin-left: 0;
            position: absolute;
            top: 0;
            .icon-search:before {
                font-size: 18px;
            }
        }
    }

    .search-button-wrapper {
        .icon-cart, .icon-cart:before {
            display: none !important;
        }
    }

    .modal-popup {
        z-index: 1000;

        .modal-header,
        .modal-content,
        .modal-footer {
            box-shadow: none;
            border: 0;
        }
    }

    button:focus,
    button:active {
        border: 0;
        box-shadow: none;
        background: inherit;
    }

    .messages {
        .message {

            a,
            a:hover,
            a:focus,
            a:active {
                color: inherit;
                text-decoration: none;
            }

            &.success {

                a,
                a:hover,
                a:focus,
                a:active {
                    color: $SECONDARY_LIGHT_COLOR;
                }
            }
        }
    }

    @media (min-width: $screen-md) {
        header.main-header-container .site-header .site-header-row .logo-wrapper {
            min-height: 85px;

            img {
                top: 13px;
            }
        }
    }
}
