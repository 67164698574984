.catalog-category-view {
    .filter-options .filter-options-title {
        margin-bottom: 0;
    }

    #am-shopby-container{
        .amshopby-filter-name{
            font-family: "DIN Next W02 Bold", sans-serif;
            font-weight: unset
        }
    }


    .swatch-attribute .swatch-attribute-options, .am-shopby-form {
        .swatch-option {
            width: 60px;
            height: 60px;

            &.image {
                width: 60px;
                height: 60px;
            }

            &.text {
                height: auto;
                width: auto;
                min-width: 80px;
                max-width: 115px;
                padding: 4px 5px;
                background: transparent;
                line-height: 1.5;
                font-family: 'DIN Next W02 Regular', sans-serif;
                font-size: .88889rem;
                font-weight: normal;
                text-align: center
            }
        }
    }

    .am-shopby-form {
        .swatch-option {
            &.selected {
                outline-color: $SECONDARY_LIGHT_COLOR;
            }
        }
    }

    .swatch-option-tooltip {
        border: 0 none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        max-width: unset;

        .image {
            background-size: cover !important;
            width: 100%;
            min-width: 120px;
            min-height: 120px;
        }

        .title {
            color: $MAIN_COLOR;
            max-height: 30px;
            max-width: 165px;
            padding: 2px 10px;
            line-height: 1.5;
            width: auto;
            overflow: visible;
            display: table;
            white-space: normal;
            margin: 0 auto;
        }

        .corner {
            width: 50px;
            height: 20px;
            position: absolute;
            transform: translateX(-50%);
            overflow: hidden;
            top: 100%;
            left: 50% !important;

            &:before {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                background: #fff;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
                left: 50%;
            }
        }
    }

    .category-badges-wrapper {
        padding: 5px 10px;
        position: absolute;
        bottom: 15px;
        right: 15px;
        background-color: $SECONDARY_LIGHT_COLOR;
        z-index: 1000;
        font-size: 16px;

        span {
            color: $BODY_BG_COLOR;
        }
        @include breakpoint(sm) {
            font-size: 20px;
            padding: 10px 20px;
        }
    }

    .sidebar.sidebar-main {
        .module-accordion_container {
            .panel {
                margin-bottom: 0;
            }

            .filter-options-content.panel-collapse.collapse.category .panel-body .item {
                a, span {
                    font-family: 'DIN Next W02 Light', sans-serif;
                    font-size: 1rem;
                    line-height: 1.33333rem;
                    font-weight: lighter;
                }
            }

            .panel-title[aria-expanded=true] {
                a:after {
                    content: $icon-arrow_up;
                }
            }

        }
    }
}
