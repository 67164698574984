.header-minicart-wrapper {
    position: absolute;
    z-index: 999;
    top: 12px;
    right: 35px;
    width: 25px;
    height: 25px;
    margin-top: 0;
    background-color: transparent;

    #minicart-content-wrapper {
        .product-item {
            font-size: 1rem;
        }
    }

    @include breakpoint(sm) {
        top: 12px;
        right: 0;
        left: calc(50% + 302px);
    }

    @include breakpoint(md) {
        top: 93px;
        left: calc(50% + 412px);
    }

    @include breakpoint(lg) {
        left: calc(50% + 512px);
    }


    .action.showcart {
        position: relative;
        background-color: transparent;
        border: 0 !important;

        &:before {
            content: '' !important;
            background-image: url("../img/wkicon_desktop.png");
            background-position: center center;
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            display: inline-block;
            color: $SECONDARY_LIGHT_COLOR;

            @include highdpi-background-image('../img/wkicon_retina.png', 23px, 22px);
        }


        .counter.qty {
            position: absolute;
            top: -11px;
            right: 0;
            left: 3px;

            color: $MAIN_COLOR;

            background: transparent;

            .counter-number {
                background-color: transparent;
                @include font-DIN-next-regular(remcalc(18px));

                text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff;
            }
        }
    }


    #top-cart-btn-checkout {
        width: 100%;
        @include font-DIN-next-regular(remcalc(18px));
    }


    &.minicart-wrapper {
        .block-minicart {
            right: -20px !important;

            padding: 35px 20px 20px 20px;
            border-color: #DDDDDD;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);

            &:before {
                right: 24px;
            }

            &:after {
                right: 23px;
            }


            #btn-minicart-close {
                opacity: 1;

                top: 28px;
                right: 6px;

                &:before {
                    @extend .dolle-icon;

                    content: $icon-x_icon;

                    color: $MAIN_COLOR;
                    font-size: remcalc(16px);
                }

                @include touch-hover-fix() {
                    opacity: 1;
                }
            }

            .items-total {
                margin: 0;
            }

        }

        #minicart-content-wrapper {
            .block-content {
                > .subtotal {
                    margin-right: 25px;

                    :last-child {
                        font-weight: 700;
                    }
                }

                .product-item-details {
                    position: relative;

                    padding-left: 100px;


                    .product-item-name {
                        padding-right: 40px;

                        a {
                            color: $SECONDARY_LIGHT_COLOR !important;
                        }
                    }


                    .product-item-pricing {
                        .details-qty {
                            > .label {
                                width: auto;
                            }
                        }
                    }


                    .product.options {
                        > .toggle {
                            margin-bottom: remcalc(8px);
                        }


                        dl.list {
                            margin-top: remcalc(16px);
                            margin-bottom: remcalc(16px);


                            > * {
                                line-height: 1;

                                margin-top: remcalc(16px);
                            }


                            dt {
                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }


                            dd {
                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }
                        }
                    }


                    .price-container {
                        @include clearfix();

                        max-height: 46px;

                        .price-label {
                            float: left;

                            padding-right: remcalc(6px);

                            font-size: remcalc(21px);
                            text-transform: uppercase;
                        }

                        .price-wrapper {
                            float: right;

                            .price-including-tax {
                                padding-right: 0;
                                margin: 0;
                                font-size: remcalc(24px);

                                .price {
                                    font-weight: normal;

                                    font-size: remcalc(21px);
                                }
                            }
                        }

                        .price-tax-hint {
                            float: left;

                            width: 90%;

                            font-size: remcalc(12px)
                        }
                    }


                    .actions {
                        .primary {
                            .action.edit {
                                display: none;
                            }
                        }

                        .secondary {
                            .action.delete {
                                position: absolute;
                                top: 0;
                                right: 0;

                                &:before {
                                    @extend .dolle-icon;

                                    content: $icon-x_icon;

                                    color: $MAIN_COLOR;
                                    font-size: remcalc(16px);
                                }
                            }
                        }
                    }
                }


                > .actions {
                    .primary {
                        margin: 0 0 15px;

                        button {
                            text-transform: uppercase;


                            &:after {
                                @extend .dolle-icon;
                                content: $icon-arrow_right;
                                font-size: remcalc(14px);
                            }
                        }
                    }

                    .secondary {
                        padding-top: 20px;
                    }
                }

                .minicart-items-wrapper {
                    margin: 0;
                    padding: 15px 0;
                    border-color: #ddd;

                    .product-image-container {
                        border: 1px solid $MAIN_LIGHT_COLOR;

                        .product-image-wrapper {
                            border: 1px solid #FFF;
                        }
                    }
                }
            }
        }
    }
}


.minicart-weltpixel {
    position: absolute;
    z-index: 999;
    top: 12px;
    right: 35px;
    width: 25px;
    height: 25px;
    margin-top: 0;
    background-color: transparent;

    #minicart-content-wrapper {
        .product-item {
            font-size: 1rem;
        }
    }

    @include breakpoint(sm) {
        top: 12px;
        right: 0;
        left: calc(50% + 302px);
    }

    @include breakpoint(md) {
        top: 93px;
        left: calc(50% + 412px);
    }

    @include breakpoint(lg) {
        left: calc(50% + 512px);
    }


    .action.showcart {
        position: relative;
        background-color: transparent;
        border: 0 !important;

        &:before {
            content: '' !important;
            background-image: url("../img/wkicon_desktop.png");
            background-position: center center;
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            display: inline-block;
            color: $SECONDARY_LIGHT_COLOR;

            @include highdpi-background-image('../img/wkicon_retina.png', 23px, 22px);
        }


        .counter.qty {
            position: absolute;
            top: -11px;
            right: 0;
            left: 3px;

            color: $MAIN_COLOR;

            background: transparent;

            .counter-number {
                background-color: transparent;
                @include font-DIN-next-regular(remcalc(18px));

                text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff;
            }
        }
    }


    #top-cart-btn-checkout {
        width: 100%;
        @include font-DIN-next-regular(remcalc(18px));
    }

    .item-data {
        .link-red {
            display: block;
            font-family: 'DIN Next W02 Regular', sans-serif;
            font-size: .88889rem;
            line-height: 1.7;
            font-weight: normal;
            color: $SECONDARY_LIGHT_COLOR;
        }

        .product-options {
            margin-top: 20px;
        }
    }
}

.checkout-cart-index {
    .module-breadcrumb {
        display: none;
    }

    .page-title {
        margin-top: 20px;

        @include breakpoint(sm) {
            margin-top: 70px;
        }
    }


    .cart-container {
        @include clearfix();
        @include make-row();


        .form.form-cart {
            float: none;

            width: 100%;


            .cart.table-wrapper {
                @include make-xs-column(12);

                .item-data {
                    .link-red {
                        display: block;
                        font-family: 'DIN Next W02 Regular', sans-serif;
                        font-size: .88889rem;
                        line-height: 1.7;
                        font-weight: normal;
                        color: $MAIN_COLOR;
                    }

                    .product-options {
                        margin-top: 20px;
                    }
                }

                table.cart.items {
                    td {
                        border-top: 0;
                    }


                    > thead {
                        th {
                            border-bottom: 0 none;

                            &.col.item {
                                width: 40%;
                            }


                            span {
                                @include font-DIN-next-regular(remcalc(20px));
                                text-transform: uppercase;
                            }
                        }
                    }


                    > tbody.cart.item {
                        width: 100%;


                        tr.item-info {
                            td.col.item {
                                @include clearfix();


                                .product-item-photo {
                                    display: block;
                                    float: left;

                                    width: 130px;
                                    max-width: 130px;
                                    padding-right: 20px;


                                    .product-image-container {
                                        padding: 1px;
                                        border: 1px solid $MAIN_BORDER_COLOR;
                                    }
                                }


                                .product-item-details {
                                    display: block;
                                    float: left;

                                    width: calc(100% - 150px);


                                    .product-item-name {
                                        a {
                                            @include font-DIN-next-regular(remcalc(20px));
                                            color: $SECONDARY_LIGHT_COLOR;
                                        }
                                    }
                                }
                            }

                            td.col.delivery-time {
                                > p {
                                    margin: 0;

                                    padding-top: remcalc(9px);

                                    @include font-DIN-next-regular(inherit);
                                }
                            }
                        }


                        tr.item-actions {
                            .actions-toolbar {
                                .action-delete {
                                    cursor: pointer;
                                }


                                .action-edit {
                                    display: none;
                                }
                            }
                        }


                        & + tbody.cart.item {
                            border-top: 0;
                        }
                    }


                    // .col styles both head and body
                    .col.subtotal {
                        text-align: right;
                    }


                    @include breakpoint(sm-only) {
                        tbody.cart.item {
                            tr.item-info {
                                td.col.item {
                                    .product-item-photo {
                                        width: 90px;
                                        max-width: 90px;
                                        padding-right: 16px;
                                    }


                                    .product-item-details {
                                        width: calc(100% - 116px);


                                        .product-attributes,
                                        .product-options {
                                            > * {
                                                width: 100%;

                                                text-align: left;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    @include breakpoint(xs-only) {
                        tbody.cart.item {
                            tr.item-info {
                                td.col.item {
                                    display: block;
                                    float: none;

                                    width: 100% !important;

                                    padding-left: 0;


                                    .product-item-photo {
                                        position: static;

                                        width: 80px;
                                        max-width: 80px;
                                        padding-right: 12px;
                                    }


                                    .product-item-details {
                                        width: calc(100% - 92px);


                                        .product-item-name {
                                            a {
                                                @include font-DIN-next-regular(18px);
                                            }
                                        }
                                    }
                                }


                                .col.delivery-time,
                                .col.price,
                                .col.qty {
                                    float: left;

                                    width: 33.33%;

                                    text-align: left;

                                    &:before {
                                        content: attr(data-th);

                                        display: block;

                                        padding-bottom: 10px;

                                        @include font-DIN-next-light(15px);
                                        text-align: left;
                                        text-transform: uppercase;
                                    }
                                }


                                .col.subtotal {
                                    overflow: hidden;

                                    width: 100%;

                                    @include font-DIN-next-light(15px);
                                    text-align: left;
                                    text-transform: uppercase;

                                    &:before {
                                        content: attr(data-th);

                                        float: left;

                                        padding: 0;

                                        font-size: 1.25rem;
                                        font-weight: normal;
                                        line-height: 1.5rem;
                                    }


                                    .price-including-tax,
                                    .price-excluding-tax {
                                        float: right;

                                        font-size: 1.5rem;
                                        line-height: 1.5rem;

                                        .cart-price {
                                            font-size: 1.5rem;
                                            line-height: 1.5rem;

                                            .price {
                                                font-size: 1.5rem;
                                                line-height: 1.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                .cart-price {
                    .price {
                        @include font-DIN-next-bold(remcalc(18px));
                    }
                }


                .control.qty {
                    @include clearfix();


                    .input-text.qty {
                        float: right;

                        border-style: solid;
                        border-width: 1px;
                        border-color: $MAIN_BORDER_COLOR;

                        box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.1);
                        width: 63px;
                        height: 52px;
                        @include font-DIN-next-regular(remcalc(20px));
                    }
                }
            }


            .cart.main.actions {
                @include make-xs-column(12);
                @include make-sm-column(6);
                @include make-lg-column(4);


                .btn {
                    width: 100%;
                    height: 40px;
                    display: block;

                    @include font-DIN-next-light(remcalc(20px), remcalc(30px));

                    &.update {
                        margin-bottom: 15px;

                        @include breakpoint(sm) {
                            margin-bottom: 170px;
                        }
                    }


                    &.continue {
                        margin-bottom: 30px;

                        @include breakpoint(sm) {
                            margin-bottom: 0;
                        }
                    }


                    .icon-arrow_right {
                        font-size: remcalc(14px);
                    }
                }
            }
        }


        .cart-summary {
            @include make-xs-column(12);
            @include make-sm-column(6);

            float: right !important;

            position: static;

            margin: 0;

            background-color: transparent;


            .cart-totals {
                padding: 0;
                border: 0;


                .totals.sub {
                    @include font-DIN-next-regular(remcalc(20px));
                    text-transform: uppercase;

                }


                .totals-tax {
                    th,
                    td {
                        padding-top: 0;
                    }
                }


                .grand.totals {
                    @include font-DIN-next-regular(remcalc(25px));
                    text-transform: uppercase;
                    color: $SECONDARY_LIGHT_COLOR;

                    &.sub {
                        color: $MAIN_COLOR;
                    }
                }
            }


            .btn.checkout {
                display: block;

                width: 100%;
                height: 40px;

                @include font-DIN-next-regular(remcalc(20px), remcalc(30px));

                @include breakpoint(sm) {
                    width: 66.6%;
                }
            }


            #block-shipping {
                > div {
                    display: none;
                }
            }

            .data.table.totals {
                tbody > tr > th,
                tbody > tr > td {
                    border: 0 none;
                    background-color: transparent;
                }
            }


            .checkout.methods {
                .btn.checkout {
                    text-transform: uppercase;
                    font-weight: normal;

                    &:after {
                        @extend .dolle-icon;
                        content: $icon-arrow_right;
                        font-size: remcalc(14px);
                    }
                }

                .ssl-note {
                    clear: both;
                    overflow: hidden;

                    width: 100%;

                    padding-top: 10px;
                    margin-left: auto;
                    margin-right: auto;


                    p {
                        min-height: 20px;

                        padding: 0 10px 0 25px;
                        margin: 0;

                        @include font-DIN-next-regular(10px, 24px);
                        text-align: left;

                        background: url("../img/icon_ssl.png") no-repeat left top;
                        background-size: 20px 20px;
                        background-position: 0 1px;
                    }


                    @include breakpoint(sm) {
                        clear: none;

                        width: 33.3%;

                        padding-top: 2px;


                        p {
                            line-height: 1.25;
                        }
                    }
                }
            }

            label {
                color: $MAIN_COLOR;
            }
        }

        .table-totals {
            .label {
                color: $MAIN_COLOR;
                display: inline-block;
                padding: 0;
                font-size: 100%;
                text-align: left;
                border-radius: 0;
            }
        }
    }

    .checkout-infos {
        @include make-row();

        clear: both;


        .checkout-infos-wrapper {
            @include clearfix();

            padding: 20px 20px 50px;
            margin: 45px 15px 0;

            background-color: $BOX_BG_COLOR_LIGHT;


            h4 {
                margin-top: remcalc(40px);
                margin-bottom: remcalc(30px);

                @include breakpoint(md) {
                    margin-top: 0;
                }
            }


            > div {
                padding: 0;

                @include breakpoint(md) {
                    @include gutterbased(left, 0.5);
                    @include gutterbased(right, 0.5);
                }
            }
        }


        .checkout-payment-info {
            @include make-xs-column(12);
            @include make-md-column(4);

            h4 {
                margin-top: 0;
            }
        }


        .checkout-security-info {
            @include make-xs-column(12);
            @include make-md-column(3);


        }


        .checkout-question-info {
            @include make-xs-column(12);
            @include make-md-column(5);


            .tel {
                @include font-DIN-next-medium(remcalc(30px));

                @include breakpoint(lg) {
                    @include font-DIN-next-medium(remcalc(50px));
                }
            }
        }
    }


    .cart-empty {
        @include make-xs-column(12);
        margin-bottom: remcalc(40px);
    }
}

.checkout.methods.items.checkout-methods-items {
    .btn.btn-primary.primary.checkout {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: $screen-sm) {

    .opc-estimated-wrapper {
        .minicart-wrapper {
            position: absolute;
            top: 12px;
            right: 0;
            margin-top: 0;

            button.action.showcart {
                border: 0 !important;
                background-color: transparent;

                &:before {
                    content: '' !important;
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    color: $SECONDARY_LIGHT_COLOR;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-image: url('../img/wkicon_retina.png');
                    background-size: 23px 22px;
                }

                .counter.qty {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    color: $MAIN_COLOR;
                    background: 0 0;
                }
            }
        }
    }
}
