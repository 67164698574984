$REVIEW_BORDER_COLOR: #c9c9c9;

#tab-label-reviews.active {
    .counter {
        color: $SECONDARY_LIGHT_COLOR;
    }
}

#reviews {
    .review-wrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        @include breakpoint(lg) {
            justify-content: space-between;
        }

        > #product-review-container, > .block.review-add {
            width: 100%;
            @include breakpoint(lg) {
                width: calc((100% / 2) - 15px);
            }
        }
    }

    #product-review-container {
        @include breakpoint(lg) {
            padding-top: 15px;
        }
    }

    .product-reviews-summary {
        margin: 15px 0 15px 5px;
        display: block;
    }

    hr {
        border-top-color: $REVIEW_BORDER_COLOR;
    }

    .review-items {
        .review-ratings {
            max-width: 100%;
            display: block;
            float: none;

            ~ .review-content, ~ .review-details {
                margin-left: 0;
            }
        }

        .review-title {
            @include font-DIN-next-bold(remcalc(16px));
            margin-bottom: 0;
        }

        .review-details {
            display: flex;

            p {
                margin-bottom: 0;
            }

            .review-date {
                margin-right: 5px;
            }
        }

        .review-content {
            margin-bottom: 0;
        }
    }

    .review-list .block-title strong, .review-field-ratings > legend {
        @include font-DIN-next-bold(remcalc(26px));
        text-transform: uppercase;
        color: $gray-base;
        margin-bottom: 5px;
        border-bottom: 0 none;
    }

    .review-add {
        background-color: $MAIN_LIGHT_COLOR;
        padding: 15px;
    }

    .review-form {
        max-width: none;
    }

    .review-fieldset {
        margin-bottom: 0;

        div.mage-error[generated] {
            font-size: remcalc(16px);
        }

        .review-field-ratings {
            margin-bottom: 30px;
            @include breakpoint(lg) {
                .nested {
                    margin-left: -5px;
                }
            }

            div.mage-error[generated] {
                margin-top: 20px;
            }
        }

        .review-field {
            margin: 0 0 10px;

            &.required {
                .control {
                    label {
                        &:after {
                            content: '*';
                            color: $ERROR_COLOR;
                            font-size: remcalc(12px);
                            margin: 0 0 0 5px;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .control {
                position: relative;
                width: 100%;

                label {
                    position: absolute;
                    left: 10px;
                    top: 8px;
                    margin-bottom: 0;
                    padding: 0;
                    opacity: 1;
                    transition: opacity 0.2s ease;
                }

                input, textarea {
                    box-shadow: none;
                    border-color: $INPUT_BORDER_COLOR;
                    width: 100%;

                    &:focus, &:not(:focus):not(:placeholder-shown) {
                        + label {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

.review-control-vote {
    &:before {
        color: #fff;
    }

    label {
        &:before {
            color: $SECONDARY_LIGHT_COLOR;
        }
    }
}

.rating-summary {
    .rating-result {
        > span {
            &:before {
                color: $SECONDARY_LIGHT_COLOR;
            }
        }
    }
}

.product-info-main {
    .product-reviews-summary {
        .rating-summary {
            left: 0;
        }

        .action.view {
            @include font-DIN-next-regular(remcalc(14px));
            color: $MAIN_COLOR;
            margin-right: 0;
            margin-top: 6px;
            display: inline-block;
        }

        .add {
            text-decoration: underline;
        }

        &:hover {
            text-decoration: none;
        }
    }
}